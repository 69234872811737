<template lang="html">
  <section className="full-calendar-app">
    <full-calendar :events="events" :config="config"/>
  </section>
</template>

<script lang="js">
import {FullCalendar} from 'vue-full-calendar';
import 'fullcalendar/dist/locale/fr';

export default {
  name: 'full-calendar-app',
  components: {
    FullCalendar
  },
  props: {
    events: Array
  },
  mounted() {
  },
  data: () => ({
   /* events: [{
      title: 'All Day Event',
      start: '2019-03-01',
      textColor: 'red',
      backgroundColor: 'black'
    },
      {
        title: 'Long Event',
        start: '2019-03-07',
        end: '2019-03-10',
        textColor: 'yellow',
        backgroundColor: 'blue'
      },
      {
        id: 999,
        title: 'Repeating Event',
        start: '2022-02-20T16:00:00',
        textColor: 'rgba(255,255,1)',
        backgroundColor: '#000'
      }],*/
    //     {
    //       title: 'Long Event',
    //       start: '2017-07-01',
    //       end: '2017-07-07'
    //     },
    //     {
    //       id: 999,
    //       title: 'Repeating Event',
    //       start: '2017-07-09T16:00:00'
    //     },
    //     {
    //       id: 999,
    //       title: 'Repeating Event',
    //       start: '2017-07-16T16:00:00'
    //     },
    //     {
    //       title: 'Conference',
    //       start: '2017-07-11',
    //       end: '2017-07-13'
    //     },
    //     {
    //       title: 'Meeting',
    //       start: '2017-07-12T10:30:00',
    //       end: '2017-07-12T12:30:00'
    //     },
    //     {
    //       title: 'Lunch',
    //       start: '2017-07-12T12:00:00'
    //     },
    //     {
    //       title: 'Meeting',
    //       start: '2017-07-12T14:30:00'
    //     },
    //     {
    //       title: 'Happy Hour',
    //       start: '2017-07-12T17:30:00'
    //     },
    //     {
    //       title: 'Dinner',
    //       start: '2017-07-12T20:00:00'
    //     },
    //     {
    //       title: 'Birthday Party',
    //       start: '2017-07-13T07:00:00'
    //     },
    //     {
    //       title: 'Click for Google',
    //       url: 'http://google.com/',
    //       start: '2017-07-28'
    //     }
    //   ],
    config: {
      locale: 'fr',
      header: {
        left: 'prev,next today',
        center: 'title',
        right: 'basicWeek,month,listYear'
      },
      buttonText: {
        listYear: 'Année',

      },
      dayNames: ['Dimanche', 'Lundi', 'Mardi', 'Mercredi', 'Jeudi', 'Vendredi', 'Samedi'],
      dayNamesShort: ['DIM', 'LUN', 'MAR', 'MER', 'JEU', 'VEN', 'SAM'],
      monthNames: ['Janvier', 'Février', 'Mars', 'Avril', 'Mai', 'Juin', 'Juillet', 'Août', 'Septembre', 'Octobre', 'Novembre', 'Décembre'],
      monthNamesShort: ['Janvier', 'Février', 'Mars', 'Avril', 'Mai', 'Juin', 'Juillet', 'Août', 'Septembre', 'Octobre', 'Novembre', 'Décembre'],
      //defaultDate: '2021-12-12',
      defaultView: 'month',
      navLinks: true,
      editable: false,
      eventLimit: true,


    }
  }),
}
</script>
<style>

</style>
